<template>
  <button :disabled="disabled || loading">
    <SpinnerComponent
        :spinner-color="'var(--primary)'"
        :type="'small'"
        v-if="loading"
    >
    </SpinnerComponent>
    <svg
        v-if="has_plus"
        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="18" height="18" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 5l0 14"></path><path d="M5 12l14 0"></path>
    </svg>

    <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  name: 'ButtonOutline',
  components: {SpinnerComponent},
  props: ['disabled', 'loading', 'has_plus'],

};
</script>

<style scoped>
  button{
    background-color: var(--text-white);
    border: 1px solid;
    color: var(--primary);
  }

  button:hover{
    background-color: var(--primary-50);
    border: 1px solid var(--primary);
    color: var(--primary);
  }

  button:active{
    background-color: var(--primary-100);
    border: 1px solid var(--primary-800);
    color: var(--primary-800);
  }

  button[disabled]{
    color: var(--primary-200);
    cursor: not-allowed;
  }

  button[disabled]:hover{
    background-color: transparent;
    border-color: var(--primary-200);
  }
</style>